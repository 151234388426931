<template>
  <div class="file-detail">
    <h1>{{ file.title }}</h1>
    <small> {{ file.createdAt | formatLongDate }}</small>
    <p>{{ file.description }}</p>

    <a class="download-url" :href="file.url" target="_blank">Download</a>

    <div v-if="hasShare">
      <button @click="shareBlog(file.title)">{{ $t("SHARE.TITLE") }}</button>
    </div>
    <ShareBox v-else :url="getPostUrl()" :title="file.title"></ShareBox>

    <a href="javascript:void(0)" title="Go Back" onclick="window.history.back()">&larr;{{ $t("GLOBAL.BACK") }}</a>
  </div>
</template>
<script>
  import FileService from '@/services/file-service'
  import ShareBox from '@/components/ShareBox.vue'

  export default {
    name: 'File',
    components: {
      ShareBox,
    },
    data() {
      return {
        hasShare: navigator.share,
        file: {},
      }
    },
    watch: {
      '$route': function(){
        const { slug } = this.$route.params;
        this.loadData(slug);
      }
    },
    mounted() {
      const { slug } = this.$route.params;
      this.loadData(slug);
    },
    methods: {
      loadData(slug) {
        FileService.detailBySlug(slug).then(resp => {
          this.file = resp;
          document.title = `Cristiano Minerals | ${resp.title}`;
        })
      },
      shareBlog(title) {
        if (navigator.share) {
          navigator.share({
            title: 'Get this file on Cristiano Minerals',
            text: title,
            url: window.location.href,
          })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
      },
      getPostUrl() {
        return window.location.href
      }
    }
  }
</script>
<style lang="scss">
  .file-detail {
    .download-url {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 2em;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>