import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import * as messages from "@/assets/i18n";

const browserLang = (navigator.language || navigator.userLanguage || "").split(
  /[-_]+/
)[0];

const localLang = localStorage.getItem("cristianominerals_lang");

if (!localLang) {
  localStorage.setItem("cristianominerals_lang", browserLang);
}

const i18n = new VueI18n({
  locale: localLang || browserLang,
  messages
});

export default i18n;
