<template>
  <div class="home">
    <h1 class="main-title">
      Cristiano Minerals - Misc
    </h1>
    <div class="search-box">
      <input type="search" :placeholder="$t('GLOBAL.SEARCH') + '...'" v-model="searchText" @search="search">
      <button type="button" @click="search">{{ $t("GLOBAL.SEARCH") }}</button>
    </div>
    <div v-for="(post, index) in posts" :key="post._id" class="post-content">
      <router-link class="title" :to="'misc/'+post.slug">
        <h2>{{ post.title }}</h2>
      </router-link>
      <small>{{ post.createdAt | formatLongDate }}</small>
      <img class="preview-image" v-if="post.previewImage" :src="post.previewImage" alt="">
      <div>
        {{ post.preview }}...
      </div>
      <div class="read-more">
        <router-link :to="'misc/'+post.slug">{{ $t("HOME.READ_MORE") }}</router-link>
      </div>
      <TagsList v-if="post.tags && post.tags.length" :tags="post.tags" @onTagClicked="getData"></TagsList>
      <button v-if="hasShare" @click="shareBlog(post.title, post.slug)">Share</button>
      <ShareBox v-else :url="getPostUrl(post.slug)" :title="post.title" :hashtags="(post.tags || []).join(',')"></ShareBox>
      <router-link v-if="isAuthenticated" :to="'post-form/'+post._id">Edit</router-link>
      <!-- <hr class="style14" v-if="index < posts.length - 1"> -->
    </div>
    <Paginator v-if="posts && total" :per-page="10" :total="total" @navigate="onPageChange" />
  </div>
</template>

<script>
import BlogService from '@/services/blog-service'
import UserService from '@/services/user-service'
import ShareBox from '@/components/ShareBox.vue'
import TagsList from '@/components/TagsList.vue'
import Paginator from '@/components/Paginator.vue';
import { EventBus } from '../event-bus'

export default {
  name: 'Misc',
  components: {
    ShareBox,
    TagsList,
    Paginator
  },
  data() {
    return {
      hasShare: navigator.share,
      isAuthenticated: UserService.isAuthenticated(),
      posts: [],
      searchText: '',
      total: 0
    }
  },
  watch: {
    '$route' (to, from) {
      // necessario para recarregar dados ao pressionar o back btn
      this.getData()
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(page = 1) {
      const query = this.$route.query;
      if (page && page > 0) {
        query.page = page-1;
      }
      query.type = 2;
      BlogService.list(query).then(resp => {
        this.posts = resp.posts
        this.total = resp.total
      })
    },
    search() {
      BlogService.list({ search: this.searchText, type: 2 }).then(resp => {
        this.posts = resp.posts
      })
    },
    shareBlog(title, slug) {
      if (navigator.share) {
        navigator.share({
          title: 'Read this Post on Cristiano Minerals',
          text: title,
          url: `${window.location.origin}/misc/${slug}`,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      }
    },
    getPostUrl(slug) {
      return `${window.location.origin}/misc/${slug}`
    },
    onPageChange(page) {
      this.getData(page.num);
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="scss">

  .main-title {
    font-size: 3em;
  }

  .home {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .post-content {
      max-width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      box-shadow: 0 1px 2px 0 rgba(0,0,0,.15);
      margin: 20px;
      padding: 10px;
      position: relative;
      margin-bottom: 50px;
      transition: all .2s ease-in-out;

      h2 {
        text-transform: capitalize;
      }
    }

    .post-content:hover {
      /*box-shadow: 0 5px 22px 0 rgba(0,0,0,.25);*/
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      margin-bottom: 54px;
    }

    .title {
      font-size: 1.5em;
    }

    img {
      max-width: 100% !important;
      height: auto !important;
    }

    .preview-image {
      display: flex;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .read-more {
      font-size: 1.2em;
      font-weight: bolder;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .search-box {
      margin-top: 10px;
      input {
        margin-right: 5px;
      }
    }
  }
</style>
