<template>
  <div class="subscribe">
    <h1>{{ $t("SUBSCRIBE.TITLE") }}</h1>
    <p>
    {{ $t("SUBSCRIBE.DESCRIPTION") }}
    </p>
    <form id="subs-form" class="subs-form" @submit.prevent="subscribe">
      <input :placeholder="$t('GLOBAL.NAME')" required type="name" name="subsName" v-model="subscription.name">
      <input :placeholder="$t('GLOBAL.EMAIL')" required type="email" name="subsEmail" v-model="subscription.email">
      <button
        type="submit"
      >{{ $t('SUBSCRIBE.SUBSCRIBE_BTN') }}</button>
    </form>
</div>
</template>
<script>

  import BlogService from '@/services/blog-service'
  import { EventBus } from '../event-bus'
  // 6LfYmJMeAAAAAMIczioYCwytfKIunNI5bjEtb-LF
  export default {
    name: "Subscribe",
    data() {
      return {
        subscription: {
          name: '',
          email: '',
        }
      }
    },
    methods: {
      subscribe() {
        BlogService.subscribe(this.subscription).then(resp => {
          this.$toast.success(this.$t("SUBSCRIBE.SUCCESS"))
          this.subscription.name = ''
          this.subscription.email = ''
          this.$router.push({ path: '/' })
        })
      }
    },
  }
</script>

